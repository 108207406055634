import React, { FC, memo, useEffect } from 'react'
import { PageProps } from 'gatsby'
import { TailSpin } from 'react-loader-spinner'

import { catalogAPI } from '@api/catalog'

import Layout from '@components/layout'
import { customNavigate, getLastPathElem } from '@utils/utils'
import { headers } from '@utils/constants'

interface IServerDataType {
  cityWithAgencyId: string
}

const Agency: FC<PageProps<object, object, unknown, IServerDataType>> = ({
  path,
  serverData: { cityWithAgencyId },
}) => {
  useEffect(() => void customNavigate(`${path}${cityWithAgencyId}`), [])

  return (
    <Layout center>
      <TailSpin color="#00AD64" height={30} width={30} />
    </Layout>
  )
}

export async function getServerData() {
  try {
    const cities = (await catalogAPI.getCities(false, true)) || []

    let firstCityWithAgencyId = '0'

    for (let city of cities) {
      if (firstCityWithAgencyId !== '0') break

      const agency = await catalogAPI.getAgencies(false, getLastPathElem(city['@id']))
      if (agency.data.length) firstCityWithAgencyId = getLastPathElem(city['@id'])
    }

    return {
      props: {
        cityWithAgencyId: firstCityWithAgencyId,
      },
      headers,
    }
  } catch (e) {
    return {
      props: {
        cityWithAgencyId: '0',
      },
      headers,
    }
  }
}

export default memo(Agency)
